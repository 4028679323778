<template>
  <Component
    :is="getHeroByType"
    :data="data"
    :data-splash="heroStyle"
    class="block-splash"
  >
    <BaseNavbar :variant="getNavVariant">
      <template v-if="data?.hero_logo" v-slot:logo>
        <NuxtLink to="/" class="flex-shrink-0">
          <span class="sr-only">{{ data.hero_logo?.original?.alt }}</span>
          <img
            :src="data.hero_logo?.original?.src"
            :alt="data.hero_logo?.original?.alt"
            class="max-h-20 max-w-40 w-auto"
            loading="eager"
          >
        </NuxtLink>
      </template>
    </BaseNavbar>
  </Component>
</template>

<script setup lang="ts">
import { defineAsyncComponent, computed, type Component } from 'vue';
import BaseNavbar from '~/components/BaseNavbar.server.vue';
import { type HeroVariants } from '~/types';

const props = defineProps({
  data: {
    required: true,
    type: Object,
  },
});

type HeroVariantMapping = {[key in HeroVariants]: Component};

const heroStyle = computed<HeroVariants>(() => props.data?.style);
const getNavVariant = computed(() => (heroStyle.value !== 'special-2') && (heroStyle.value !== 'dark') ? 'dark' : 'light');
const getHeroByType = computed(() => {
  const style: HeroVariants = heroStyle.value || 'fancy';
  const mapping: Partial<HeroVariantMapping> = {
    dark: defineAsyncComponent(() => import('./../Splash01A.vue')),
    fancy: defineAsyncComponent(() => import('./../Splash01B.vue')),
    'fancy-centered': defineAsyncComponent(() => import('./../Splash01C.vue')),
    special: defineAsyncComponent(() => import('./../Splash02A.vue')),
  };

  return mapping[style];
});
</script>
